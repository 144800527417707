import styled from "styled-components"
import React from "react"
import { graphql, useStaticQuery, Link, navigate } from "gatsby"
/*import Shape from "../images/shape.inline.svg"*/

import FacebookIcon from "../images/social-media/facebook.inline.svg"
import TwitterIcon from "../images/social-media/twitter.inline.svg"
import LinkedinIcon from "../images/social-media/linkedin.inline.svg"
import InstagramIcon from "../images/social-media/instagram.inline.svg"
import GitHubIcon from "../images/social-media/github.inline.svg"

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 25px 35px;

  @media (min-width: ${(props) => props.theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${(props) => props.theme.screen.md}) {
    max-width: 960px;

    ${(props) =>
      props.shadow &&
      `
      box-shadow: 0 30px 30px 0 rgba(0,0,0,.132),0 -5px 15px 0 rgba(0,0,0,.108);
    `};
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    max-width: 1200px;
  }

  ${(props) =>
    props.narrow &&
    `
    max-width: 960px !important;
  `};

  ${(props) =>
    props.shadow &&
    `
    background-color: ${props.theme.color.white.regular};
    color: ${props.theme.color.primary};
  `};
`

export const Section = styled.section`
  padding: 80px 0;
  overflow: hidden;
  
  background-color: ${(props) => {
    switch (props.type) {
      case "secondary":
        return props.theme.color.white.dark
      case "accent":
        return props.theme.color.accent
      case "black":
        return props.theme.color.black.regular
      default:
        return props.theme.color.white.regular
    }
  }};

  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding: 80px 0;
  }

  ${(props) =>
    props.type &&
    `color: ${
      props.type === "accent" || props.type === "black"
        ? props.theme.color.white.regular
        : props.theme.color.primary
    }`};
  };
  
`
export const SectionTitle = styled.h3`
  text-align: center;
  margin-top: 0;
  margin-bottom: 4rem;
`

export const Subtitle = styled.h5`
  ${(props) => props.theme.font_size.xsmall}
  letter-spacing: 0px;
  margin: 0 0 20px;
  text-align: center;
`

export const ImageContainer = styled.figure`
  display: block;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.white.darker};
  overflow: hidden;
  margin: 0 auto 20px;
  padding-bottom: 100%;
  position: relative;
  ${(props) => props.theme.animation.regular};

  &:hover {
    background-color: ${(props) => props.theme.color.accent};
  }

  & > img {
    width: 100%;
    position: absolute;
    top: 0;
  }
`

export const ProductsList = (props) => {
  const data = props.products

  return (
    data && (
      <ProductItemsList>
        {data.map(({ node }) => (
          <ProductItem
            title={node.title}
            onClick={() => {
              navigate(node.fields.slug)
            }}
          >
            <ProductImageContainer>
              <ProductImage>
                <img src={node.iconImage.publicURL} alt={node.title} />
              </ProductImage>
            </ProductImageContainer>
            <ProductTitle>{node.title}</ProductTitle>
            <ProductDescription>{node.shortDescription}</ProductDescription>
            <ProductLinkContainer>
              <ProductLink
                to={node.fields.slug}
                title={`Learn more about ${node.title}`}
              >
                Learn more
              </ProductLink>
            </ProductLinkContainer>
          </ProductItem>
        ))}
      </ProductItemsList>
    )
  )
}

const ProductItemsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -17px;
`

const ProductItem = styled.div`
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    width: calc(33.33% - 35px);
  }
  margin: 10px 17px;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  padding: 35px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.color.white.regular};

  &:hover figure {
    background-color: ${(props) => props.theme.color.accent};
  }
`
const ProductImageContainer = styled.div`
  max-width: 65px;
  width: 100%;
  margin: 0 auto;
`

const ProductImage = styled(ImageContainer)`
  margin-bottom: 0;
`

const ProductTitle = styled.h4`
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
`

const ProductDescription = styled.p`
  text-align: center;
  /*line-height: 2.2em;*/
  margin-bottom: 25px;
`

const ProductLink = styled((props) => <Link {...props} />)`
  text-decoration: none;
  color: ${(props) => props.theme.color.white.regular};
  background-color: ${(props) => props.theme.color.accent};
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 1rem 1rem 0.675rem;
  font-weight: bold;
  /*min-width: 150px;*/
  display: inline-block;
`

const ProductLinkContainer = styled.div`
  text-align: center;
  margin-top: auto;
`

export const AccentText = styled.span`
  /* text-decoration: underline;*/
  font-weight: bolder;
  font-size: 1.15em;
`

export const EmphasisContainer = styled(Container)`
  text-align: justify;
`

export const EmphasisTitle = styled(SectionTitle)`
  text-align: left;
  line-height: 2.5rem;

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    text-align: center;
    ${(props) => props.theme.font_size.larger}
  }
`

export const EmphasisDescription = styled.div`
  ${(props) => props.theme.font_size.large}
  line-height: 2.5rem;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    ${(props) => props.theme.font_size.regular}
  }

  p {
    font-size: inherit;
    line-height: inherit;
    margin: 0 0 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }
`

export const SocialMediaList = (props) => {
  const getSocialMediaIcon = (name) => {
    switch (name) {
      case "Facebook":
        return <FacebookIcon />
      case "Twitter":
        return <TwitterIcon />
      case "Linkedin":
        return <LinkedinIcon />
      case "Instagram":
        return <InstagramIcon />
      case "GitHub":
        return <GitHubIcon />
      default:
        return <></>
    }
  }
  const data = useStaticQuery(graphql`
    query {
      allSocialMediaJson {
        edges {
          node {
            name
            link
          }
        }
      }
    }
  `)

  return (
    <SocialMediaContainer center={props.center}>
      {data.allSocialMediaJson.edges.map(({ node }) => (
        <SocialMediaItem large={props.large}>
          <SocialMediaLink
            href={node.link}
            title={node.name}
            target="_blank"
            large={props.large}
            dark={props.dark}
          >
            {getSocialMediaIcon(node.name)}
          </SocialMediaLink>
        </SocialMediaItem>
      ))}
    </SocialMediaContainer>
  )
}

const SocialMediaContainer = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  ${(props) => props.center && `justify-content: center;`}
`

const SocialMediaItem = styled.li`
  :not(:last-child) {
    ${(props) =>
      props.large ? `margin-right: 1.5rem;` : `margin-right: 0.75rem;`}
  }
  svg {
    ${(props) => (props.large ? `height: 2rem;` : `height: 1rem;`)}
  }
`

const SocialMediaLink = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;

  ${(props) =>
    props.large
      ? `width: 4rem; height: 4rem;`
      : `width: 2.5rem; height: 2.5rem;`}

  border-width: 0.0625rem;
  border-style: solid;

  border-radius: 50%;
  ${(props) => props.theme.animation.regular}

  border-color: ${(props) =>
    props.dark ? props.theme.color.white.regular : props.theme.color.primary};

  &,
  &:link,
  &:visited {
    color: ${(props) =>
      props.dark ? props.theme.color.white.regular : props.theme.color.primary};
  }

  :hover {
    color: ${(props) =>
      props.dark ? props.theme.color.accent : props.theme.color.white.regular};
    border-color: ${(props) =>
      props.dark ? props.theme.color.white.regular : props.theme.color.accent};
    background-color: ${(props) =>
      props.dark ? props.theme.color.white.regular : props.theme.color.accent};
  }
`
/*
  border-color: ${(props) => props.theme.color.white.regular};
  border-radius: 50%;
  ${(props) => props.theme.animation.regular}

  :hover {
    background-color: ${(props) => props.theme.color.white.regular};
*/
