import styled from "styled-components"

import { Container } from "../../global"

export const Nav = styled.nav`
  /*padding: ${(props) => (props.scrolled ? `16px 0` : `24px 0`)};*/
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: ${(props) => props.theme.color.background.white};
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
`

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & svg {
    max-height: 35px;
  }
`

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    ${(props) => props.theme.font_size.regular};

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;
        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`

export const NavItem = styled.li`
  margin: 0 1.5em;
  font-family: ${(props) => props.theme.font.medium};
  ${(props) => props.theme.font_size.xsmall};

  a {
    text-decoration: none;
    opacity: 0.9;
    color: ${(props) => props.theme.color.black.regular};
  }

  &.active {
    a {
      opacity: 1;
    }
  }

  &.submenu-parent {
    position: relative;
    padding-right: 1.5rem;
    transition: 0.3s ease all;
  }

  &.submenu-parent:after {
    content: "";
    cursor: pointer;
    position: absolute;
    top: 0.75rem;
    right: 0;
    border-bottom: 2px solid ${(props) => props.theme.color.black.regular};
    border-right: 2px solid ${(props) => props.theme.color.black.regular};
    display: inline-block;
    margin-left: 1rem;
    height: 0.5rem;
    width: 0.5rem;
    transition: 0.3s ease all;
    transform: translateY(-50%) rotate(45deg);
  }
  /*
  &.submenu-parent:hover:after{
    transform: translateY(-50%) rotate(-135deg);
    top: 1rem;
  }*/
`

export const Submenu = styled.div`
  transition: 0.3s ease all;
  white-space: nowrap;
  background: ${(props) => props.theme.color.background.white};

  padding: 25px 15px 15px;
  box-shadow: 0 30px 30px -10px rgb(0 0 0 / 20%);
  position: absolute;
  top: 100%;
  left: -50%;
  overflow: hidden;
  width: auto;

  &.ProductsMenu {
    left: -530px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    &.ProductsMenu {
      left: -280px;
    }
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    &.ProductsMenu {
      left: 0;
    }
  }

  ${({ mobile }) =>
    mobile &&
    `
      overflow: auto;
      box-shadow: unset;
      padding: 0;
      position: relative;
      margin-top: 1em;

      &.ProductsMenu{
        left: 0;
      }

      > ${NavItem} {
        margin: 0;
        margin-top: 0.75em;
      }
    `};
`

export const MobileMenu = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  padding-bottom: 25px;
  box-sizing: border-box;
  z-index: 1000;
  overflow-y: auto;
  background: ${(props) => props.theme.color.background.white};
`

export const Brand = styled.div`
  font-family: ${(props) => props.theme.font.extrabold};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  a {
    color: ${(props) => props.theme.color.black.regular};
    text-decoration: none;

    svg {
      min-width: 140px;
    }
  }
`
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    display: none;
  }

  button {
    font-family: ${(props) => props.theme.font.normal};
    ${(props) => props.theme.font_size.xsmall};
    color: white;
    background: ${(props) => props.theme.color.secondary};
    border-radius: 4px;
    padding: 10px 16px;
    text-transform: uppercase;
    font-size: 12px;
  }
`

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    display: block;
  }

  ${(props) =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.xs}) {
      display: none;
    }
  `}
`
