import React, { Component } from "react"
import { Menu, X } from "react-feather"
import { graphql, Link, StaticQuery } from "gatsby"

import { Container } from "../../global"
import ProductsMenu from "./products-menu"
import Logo from "../../../images/branding/logo.inline.svg"

import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  Submenu,
} from "./style"

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileMenuOpen: false,
      hasScrolled: false,
      activeSubmenu: null,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleSubNav = (subNav) => {
    this.setState({
      activeSubmenu: this.state.activeSubmenu === subNav ? null : subNav,
    })
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
    }))
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false })
    }
  }

  getChildControl = ({ control, mobile = false }) => {
    switch (control) {
      case "ProductsMenu":
        return <ProductsMenu mobile={mobile} />
      default:
        return <></>
    }
  }

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}></NavListWrapper>
  )

  getNavListOld = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <ul>
        {this.props.data.allHeaderJson.edges.map(({ node }) => (
          <NavItem
            key={node.name}
            className={node.childControl ? "submenu-parent" : ""}
            onMouseEnter={() => {
              if (!mobile) this.toggleSubNav(node)
            }}
            onMouseLeave={() => {
              if (!mobile) this.toggleSubNav(null)
            }}
            onClick={() => {
              if (mobile) this.toggleSubNav(node)
            }}
          >
            <Link to={node.link} title={node.name}>
              {node.name}
            </Link>
            {node.childControl && (
              <Submenu
                mobile={mobile}
                className={node.childControl}
                style={{
                  display: this.state.activeSubmenu !== node ? "none" : "block",
                }}
              >
                {this.getChildControl({ control: node.childControl, mobile })}
              </Submenu>
            )}
          </NavItem>
        ))}
      </ul>
    </NavListWrapper>
  )

  render() {
    const { mobileMenuOpen } = this.state

    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled}>
        <StyledContainer>
          <Brand>
            <Link to="/" title="Home" onClick={this.closeMobileMenu}>
              <Logo />
            </Link>
          </Brand>
          <Mobile>
            <button
              onClick={this.toggleMobileMenu}
              style={{ color: "black", background: "none" }}
            >
              {this.state.mobileMenuOpen ? (
                <X size={24} alt="close menu" />
              ) : (
                <Menu size={24} alt="open menu" />
              )}
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query MainMenuQuery {
        allHeaderJson {
          edges {
            node {
              name
              link
              childControl
            }
          }
        }
      }
    `}
    render={(data) => <Navigation data={data} />}
  />
)
