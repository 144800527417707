import styled from "styled-components"
import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const ProductsMenu = (props) => {
  const mobile = props.mobile
  const data = useStaticQuery(graphql`
    query {
      products: allProductsJson(sort: { fields: [weight], order: DESC }) {
        edges {
          node {
            fields {
              slug
            }
            title
            shortDescription
            category
            iconImage {
              publicURL
            }
          }
        }
      }
    }
  `)
  const groupedByCategory = data.products.edges.reduce(function (acc, item) {
    var category = item.node.category
    ;(acc[category] = acc[category] || []).push(item)
    return acc
  }, {})
  return (
    <CategoriesContainer mobile={mobile}>
      {Object.keys(groupedByCategory).map((category) => (
        <CategoryGroup mobile={mobile}>
          <CategoryGroupTitle mobile={mobile}>{category}</CategoryGroupTitle>
          {groupedByCategory[category].map(({ node }) => (
            <ProductItem to={node.fields.slug} title={node.title}>
              <img src={node.iconImage.publicURL} alt={node.title} />
              <div>
                <ProductItemTitle>{node.title}</ProductItemTitle>
                <ProductItemDescription>
                  {node.shortDescription}
                </ProductItemDescription>
              </div>
            </ProductItem>
          ))}
        </CategoryGroup>
      ))}
    </CategoriesContainer>
  )
}

export default ProductsMenu

const CategoriesContainer = styled.div`
  @media (min-width: ${(props) => props.theme.screen.xs}) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    width: 610px;
  }

  @media (min-width: ${(props) => props.theme.screen.md}) {
    width: 860px;
  }
`

const CategoryGroup = styled.div`
  border-left-color: ${(props) => props.theme.color.accent};
  ${({ mobile }) =>
    !mobile &&
    `&:not(:first-child){
            /*border-left-width: 1px;
            border-left-style: solid;*/
        }

        padding: 0 25px 25px;
        margin: 20px 0;
        width: 280px;
    `};
`

const CategoryGroupTitle = styled.div`
  ${(props) => props.theme.font_size.small}
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 0.25rem;
  /* text-align: center;*/

  ${({ mobile }) =>
    mobile &&
    `
    text-align: left;
    `};
`

const ProductItemTitle = styled.div`
  margin-bottom: 0;
`

const ProductItem = styled(Link)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.75rem;

  & img {
    height: 2.5rem;
    max-width: 2.5rem;
    margin-right: 0.75rem;
    margin-top: 0.5rem;
  }

  &:hover ${ProductItemTitle} {
    color: ${(props) => props.theme.color.accent};
  }
`

const ProductItemDescription = styled.div`
  ${(props) => props.theme.font_size.xxxsmall}
  line-height: 1.1rem;
  max-width: ${(props) => props.theme.screen.xs};
  white-space: normal;
`
