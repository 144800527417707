import React from "react"
import PropTypes from "prop-types"
import Seo from "./seo"
import Navigation from "../navigation/navigation"
import Footer from "../../sections/footer"
import styled from "styled-components"
import CookieConsent from "react-cookie-consent"
import { ThemeProvider } from "styled-components"

import theme from "../../../styles/theme"
import GlobalStyles from "../../../styles/GlobalStyles"
//import "../../../static/fonts/fonts.css"

const LayoutOld = ({ children, description, lang, meta, title, location }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Seo
      title={title}
      description={description}
      lang={lang}
      meta={meta}
      location={location}
    />
    <Navigation />
    <ContentWrapper>{children}</ContentWrapper>
    <Footer />
  </ThemeProvider>
)

const Layout = ({ children, description, lang, meta, title, location }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Seo
      title={title}
      description={description}
      lang={lang}
      meta={meta}
      location={location}
    />
    <Navigation />
    <ContentWrapper>{children}</ContentWrapper>
  </ThemeProvider>
)

/*<CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
    >
      <h3>This website uses cookies</h3>
      <p>
        We use cookies to personalise content and ads, to provide social media
        features and to analyse our traffic. We also share information about
        your use of our site with our social media, advertising and analytics
        partners who may combine it with other information that you’ve provided
        to them or that they’ve collected from your use of their services. You
        consent to our cookies if you continue to use our website.
      </p>
    </CookieConsent>
*/
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const ContentWrapper = styled.div`
  padding-top: 80px;
  position: relative;
  min-height: calc(100vh);
`
