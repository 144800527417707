import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import theme from "../../../styles/theme"
import SmallLogo from "../../../images/logo-small.inline.svg"

const SEO = ({ description, lang, meta, title, location }) => {
  const { site, brandingImages } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        brandingImages: allFile(
          filter: { sourceInstanceName: { eq: "branding" } }
        ) {
          edges {
            node {
              name
              publicURL
            }
          }
        }
      }
    `
  )
  const siteName = `.action`

  const host = site.siteMetadata.siteUrl
  let currentUrl = host

  if (location.pathname && location.pathname != "/") {
    currentUrl += location.pathname.trimEnd("/")
  }

  const metaTitle = title || site.siteMetadata.title
  const titleTemplate = `%s` // title ? `%s | ${site.siteMetadata.title}` : `%s`
  const metaDescription = description || site.siteMetadata.description

  const mappedIcons = {}
  brandingImages.edges.map(({ node }) => {
    mappedIcons[node.name] = host + node.publicURL
    return node.name
  })

  const twitterImage = mappedIcons["website-featured-img"]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      link={[
        {
          rel: `apple-touch-icon`,
          sizes: `57x57`,
          href: `${mappedIcons["apple-icon-57x57"]}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `60x60`,
          href: `${mappedIcons["apple-icon-60x60"]}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `72x72`,
          href: `${mappedIcons["apple-icon-72x72"]}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `76x76`,
          href: `${mappedIcons["apple-icon-76x76"]}`,
        },

        {
          rel: `apple-touch-icon`,
          sizes: `114x114`,
          href: `${mappedIcons["apple-icon-114x114"]}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `120x120`,
          href: `${mappedIcons["apple-icon-120x120"]}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `144x144`,
          href: `${mappedIcons["apple-icon-144x144"]}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `152x152`,
          href: `${mappedIcons["apple-icon-152x152"]}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `180x180`,
          href: `${mappedIcons["apple-icon-180x180"]}`,
        },
        {
          rel: `icon`,
          type: `image/png`,
          sizes: `192x192`,
          href: `${mappedIcons["android-icon-192x192"]}`,
        },
        {
          rel: `icon`,
          type: `image/png`,
          sizes: `16x16`,
          href: `${mappedIcons["favicon-16x16"]}`,
        },
        {
          rel: `icon`,
          type: `image/png`,
          sizes: `32x32`,
          href: `${mappedIcons["favicon-32x32"]}`,
        },
        {
          rel: `icon`,
          type: `image/png`,
          sizes: `96x96`,
          href: `${mappedIcons["favicon-96x96"]}`,
        },
        {
          rel: `manifest`,
          href: `${mappedIcons["manifest"]}`,
        },
        {
          rel: `mask-icon`,
          href: `${SmallLogo.publicURL}`,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: siteName,
        },
        {
          property: `og:url`,
          content: currentUrl,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${twitterImage}`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        /*{
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },*/
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        /* {
          name: `twitter:site`,
          content: `@dotaction_io`,
        },*/
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `${twitterImage}`,
        },
        {
          name: `twitter:image:alt`,
          content: metaTitle,
        },
        {
          name: `theme-color`,
          content: theme.color.accent,
        },
        {
          name: `msapplication-TileColor`,
          content: theme.color.accent,
        },
        {
          name: `msapplication-TileImage`,
          content: `${mappedIcons["ms-icon-144x144"]}`,
        },
        {
          name: `google-site-verification`,
          content: `b5p_iGJ_qxmAeLTnQ3gZ2rGOBUnu9VVA_b4yPwT_Gqg`,
        },
      ].concat(meta)}
      script={[
        {
          id: `Cookiebot`,
          type: `text/javascript`,
          src: `https://consent.cookiebot.com/uc.js`,
          "data-cbid": `f9f2a283-5c64-4e53-acab-d8c58ae41abe`,
          "data-blockingmode": `auto`,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
