import React from "react"
import styled from "styled-components"
import Logo from "../../images/branding/logo-footer.inline.svg"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Container, SocialMediaList } from "../global"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allSocialMediaJson {
        edges {
          node {
            name
            link
          }
        }
      }
      allProductsJson(sort: { fields: [weight], order: DESC }) {
        edges {
          node {
            id
            title
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  return (
    <FooterWrapper>
      <FooterContentContainer>
        <FooterTopRow>
          <BrandContainer>
            <LogoContainer>
              <Link to="/" title="Home">
                <Logo />
              </Link>
            </LogoContainer>
            <SocialMediaList dark="true"></SocialMediaList>
          </BrandContainer>
          <FooterMenuContainer>
            <FooterColumn>
              <FooterColumnHeader>Products</FooterColumnHeader>
              <FooterColumnMenu>
                {data.allProductsJson.edges.map(({ node }) => (
                  <FooterColumnMenuItem>
                    <Link to={node.fields.slug} title={node.title}>
                      {node.title}
                    </Link>
                  </FooterColumnMenuItem>
                ))}
              </FooterColumnMenu>
            </FooterColumn>
            <FooterColumn>
              <FooterColumnHeader>Company</FooterColumnHeader>
              <FooterColumnMenu>
                <FooterColumnMenuItem>
                  <Link to="/about" title="About">
                    About
                  </Link>
                </FooterColumnMenuItem>
                <FooterColumnMenuItem>
                  <Link to="/contact" title="Contact">
                    Contact
                  </Link>
                </FooterColumnMenuItem>
              </FooterColumnMenu>
            </FooterColumn>
          </FooterMenuContainer>
        </FooterTopRow>
        <FooterBottomRow>
          <CopyrightContainer>
            © {new Date().getFullYear()} All rights reserved
          </CopyrightContainer>
          <FooterBottomMenu>
            <FooterBottomMenuItem>
              <Link to="/privacy-policy" title="Privacy and cookie policy">
                Privacy and cookie policy
              </Link>
            </FooterBottomMenuItem>
          </FooterBottomMenu>
        </FooterBottomRow>
      </FooterContentContainer>
    </FooterWrapper>
  )
}
/* <FooterBottomMenuItem>
              <Link to="/terms-of-use" title="Terms of use">
                Terms of use
              </Link>
            </FooterBottomMenuItem>
*/
const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.black.regular};
  color: ${(props) => props.theme.color.white.regular};
  /* margin: 80px 0 0;*/
  a {
    color: ${(props) => props.theme.color.white.regular};
    text-decoration: none;
    ${(props) => props.theme.animation.regular}
    :hover {
      color: ${(props) => props.theme.color.accent};
    }
  }
`

const BrandContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*margin-right: 16px;*/

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-bottom: 30px;
  }
`

const FooterMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
  min-width: 50%;
`

const LogoContainer = styled.div`
  font-family: ${(props) => props.theme.font.extrabold};
  ${(props) => props.theme.font_size.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: 0px;

  a {
    display: inline-block;
    svg {
      color: ${(props) => props.theme.color.white.regular};
      width: 185px;
      margin-right: 16px;
    }
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-bottom: 30px;
  }
`

const FooterContentContainer = styled(Container)`
  padding-top: 24px;
`

const FooterTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    /* min-height: 200px;*/
  }
`

const FooterBottomRow = styled.div`
  padding: 25px 0;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const CopyrightContainer = styled.div``

const FooterBottomMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
`

const FooterBottomMenuItem = styled.li`
  margin-left: 15px;
`

const FooterColumn = styled.div`
  padding: 0 16px;
  flex-grow: 1;
`

const FooterColumnHeader = styled.h4`
  margin: 0 0 15px;
  ${(props) => props.theme.font_size.regular}
  font-family: ${(props) => props.theme.font.bold};
`

const FooterColumnMenu = styled.ul`
  list-style: none;
  margin: 16px 0;
  padding: 0;
`
const FooterColumnMenuItem = styled.li`
  line-height: 2.5rem;
  white-space: nowrap;
`

export default Footer
